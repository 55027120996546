import { twMerge } from 'tailwind-merge';

import {
  badgeVariantStyles,
  badgeVariantTextStyles,
} from '@components/ratingBadge/constants';

import { RatingsBadgeIcon } from '@shared/assets';
import { getProductRatingsRange } from '@shared/helpers/ratingColorHelper';
import { formatPriceString } from '@shared/utils';

interface IProps {
  rating: number | string;
  className?: string;
}
const RatingBadge = ({ rating, className }: IProps) => {
  const parsedRating = formatPriceString(rating.toString(), 1);

  const productRatingRange = getProductRatingsRange(parsedRating);

  return (
    <div
      className={twMerge(
        'flexCenter w-fit',
        className,
        badgeVariantStyles[productRatingRange]
      )}
    >
      <RatingsBadgeIcon className="relative" />
      <div className="flexCenter absolute size-8">
        <p
          className={twMerge(
            'label-medium',
            badgeVariantTextStyles[productRatingRange]
          )}
        >
          {rating === 0 ? '-' : parsedRating?.toFixed(1)}
        </p>
      </div>
    </div>
  );
};

export default RatingBadge;
