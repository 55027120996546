import Image from 'next/image';

import { AitechTokenImage } from '@shared/assets';
import { USDAI_TOKEN } from '@shared/constants/siteInfo';
import useCalculateUserUSDAIPrice from '@shared/hooks/price/useCalculateUserUSDAIPrice';

interface IProps {
  price?: number;
}

const PriceWrapper = ({ price }: IProps) => {
  const { userPrice } = useCalculateUserUSDAIPrice(price);

  return (
    <div className="align-center flex items-center gap-1">
      <Image src={AitechTokenImage} alt="AITECH token" className="size-5" />
      {price && price > 0 ? (
        <>
          {' '}
          <p className="label-xlarge tracking-[0.52px] text-white">
            {USDAI_TOKEN}
          </p>{' '}
          <p className="label-xlarge tracking-[0.52px] text-green500">
            {userPrice}
          </p>
        </>
      ) : (
        <p className="label-xlarge tracking-[0.52px] text-green500">{'FREE'}</p>
      )}
    </div>
  );
};
export default PriceWrapper;
