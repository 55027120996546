import { useMemo } from 'react';

import { twMerge } from 'tailwind-merge';

import PaginationItems from '@components/table/PaginationItems';
import { getPaginationItems } from '@components/table/utils';

import DirectionalArrow from './directionalArrow';

interface IProps {
  onBackClick: () => void;
  onForwardClick: () => void;
  onPageChange: (page: number) => void;
  currentPage: number;
  pageCount: number;
  className?: string;
}

const GridPagination = ({
  currentPage,
  onPageChange,
  onBackClick,
  onForwardClick,
  pageCount,
  className,
}: IProps) => {
  const paginationItems = useMemo(
    () => getPaginationItems(currentPage, pageCount),
    [currentPage, pageCount]
  );

  return (
    <div className={twMerge('flexCenter mt-9 w-full gap-3', className)}>
      <DirectionalArrow
        direction="left"
        onClick={onBackClick}
        isDisabled={currentPage === 1}
      />

      <PaginationItems
        paginationItems={paginationItems}
        currentPage={currentPage}
        onPageChange={onPageChange}
      />
      <DirectionalArrow
        direction="right"
        onClick={onForwardClick}
        isDisabled={currentPage === pageCount}
      />
    </div>
  );
};

export default GridPagination;
