import Image from 'next/image';
import { useRouter } from 'next/navigation';
import { MouseEvent } from 'react';

import { UserGetProductResPublisherData } from '@interfaces';
import { twMerge } from 'tailwind-merge';

import Ellipsis from '@components/Ellipsis';

import { ProfileUserIcon } from '@shared/assets';
import { isValidImageSrc } from '@shared/helpers/validateImgSrc';
import { PUBLISHER_PROFILE } from '@shared/utils';

interface IProps {
  user?: UserGetProductResPublisherData['user'];
  className?: string;
  disableLink?: boolean;
}

const CompanyAvatar = ({ user, className, disableLink }: IProps) => {
  const router = useRouter();
  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    router.push(PUBLISHER_PROFILE(user?.id));
  };

  //TODO mozda maknut, tu je jer seedaju krive url-ove
  const isValidImg = isValidImageSrc(user?.avatar);

  return (
    <button
      {...(!disableLink && { onClick: handleClick })}
      className={twMerge(
        'relative z-40 flex flex-1 items-center gap-2',
        className
      )}
    >
      <div className="relative size-10 shrink-0">
        {!!isValidImg && !!user?.avatar ? (
          <Image
            src={user.avatar}
            alt="company-avatar"
            fill
            className="roundedBlTr border border-neutral400 object-cover"
          />
        ) : (
          <div className="roundedBlTr flex size-10 items-center justify-center border border-neutral400">
            <ProfileUserIcon className="size-8" />
          </div>
        )}
      </div>
      <Ellipsis
        title={user?.name || ''}
        id={user?.id?.toString()}
        className="label-large !normal-case text-neutral200"
        hideIcon
      />
    </button>
  );
};
export default CompanyAvatar;
