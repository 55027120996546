import { useMutation } from '@tanstack/react-query';

import { toastError } from '@shared/utils';

import { productApi } from '../productApi';

const useUnBookmarkProductMutation = () => {
  return useMutation({
    mutationFn: async (productId: number) =>
      productApi.client.unBookmark(productId),
    onError: (e) => {
      toastError(e.message);
    },
  });
};

export default useUnBookmarkProductMutation;
