import { twMerge } from 'tailwind-merge';

import { Bookmark } from '@shared/assets';
import { useDebounceStatusChange } from '@shared/hooks';

interface IProps {
  isBookmarked: boolean;
  onClick: () => Promise<void>;
}

const BookmarkButton = ({ isBookmarked, onClick }: IProps) => {
  const {
    handleStatusUpdate: handleDelayedBookmark,
    isLocalStatus: isLocalBookmarked,
  } = useDebounceStatusChange(onClick, {
    initialStatus: !!isBookmarked,
  });

  return (
    <div
      role="button"
      tabIndex={0}
      className="flexCenter absolute right-2 top-2 z-50 cursor-pointer"
      onClick={async (e) => {
        e.stopPropagation();
        handleDelayedBookmark();
      }}
    >
      <Bookmark
        className={twMerge(
          'size-5',
          !isLocalBookmarked &&
            'customTransition opacity-0 group-hover:opacity-100',
          isLocalBookmarked
            ? 'fill-yellow500 stroke-yellow500'
            : 'fill-transparent stroke-white'
        )}
      />
    </div>
  );
};

export default BookmarkButton;
