import { RatingBadgeType } from '@shared/helpers/ratingColorHelper';

export const badgeVariantStyles = {
  [RatingBadgeType.good]: 'text-brandPrimary',
  [RatingBadgeType.medium]: 'text-yellow500',
  [RatingBadgeType.bad]: 'text-red600',
  [RatingBadgeType.none]: 'text-neutral500',
};

export const badgeVariantTextStyles = {
  [RatingBadgeType.good]: 'text-black',
  [RatingBadgeType.medium]: 'text-black',
  [RatingBadgeType.bad]: 'text-white',
  [RatingBadgeType.none]: 'text-white',
};
