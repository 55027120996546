import useGetUserFeeSettings from '@api/user/settings/queries/useGetUserFeeSettings';

import { formatDisplayCurrency } from '@features/utils/numbers';

const useCalculateUserUSDAIPrice = (price?: string | number) => {
  const { totalFee } = useGetUserFeeSettings();

  return { userPrice: price && +price > 0 ? formatDisplayCurrency(Number(price) / (1 - totalFee)) : '0' };
};

export default useCalculateUserUSDAIPrice;
