import { cutOffString } from '@shared/utils';

interface IProps {
  tag: string;
}

const MAX_TAG_LENGTH = 20;

const ProductCardTag = ({ tag }: IProps) => {
  return (
    <p className="border-light700 label-small border px-1.5 pt-0.5 text-neutral500">
      {cutOffString(tag, MAX_TAG_LENGTH)}
    </p>
  );
};

export default ProductCardTag;
