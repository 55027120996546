import { useMemo } from 'react';

import { useQuery } from '@tanstack/react-query';

import { settingsApi } from '@api/user/settings/settingsApi';

import { USER_FEE_SETTINGS } from '@shared/constants/queryKeys';

const useGetUserFeeSettings = () => {
  const { data } = useQuery({
    queryKey: [USER_FEE_SETTINGS],
    queryFn: () => settingsApi.client.getUserFeeSettings(),
  });

  const feeStake = data?.data?.find((item) => item.feeSettingType === 'stake');
  const feeBurn = data?.data?.find((item) => item.feeSettingType === 'burn');

  return {
    feeStake,
    feeBurn,
    totalFee: useMemo(
      () => Number(feeStake?.feeAmount) + Number(feeBurn?.feeAmount),
      [feeStake, feeBurn]
    ),
  };
};

export default useGetUserFeeSettings;
