import { ISingleThread } from '@interfaces/forum/user/myThreads';
import { QueryClient } from '@tanstack/react-query';

import {
  BILLION,
  HUNDRED,
  HUNDRED_MILLION,
  HUNDRED_THOUSAND,
  MILLION,
  THOUSAND,
} from '@shared/constants';
import { FORUM_KEYS } from '@shared/constants/queryKeys';

import {
  IThreadFooterProps,
  IThreadOverviewContentProps,
} from './singleThread/types';

export const formatBigNumber = (number?: number | string): string => {
  if (!number) return '0';

  const intNumber = +number;

  const getDecimals = (larger: number, smaller: number) =>
    intNumber % larger >= smaller ? 1 : 0;

  if (intNumber >= BILLION) {
    return `${(intNumber / BILLION).toFixed(getDecimals(BILLION, HUNDRED_MILLION))}B`;
  } else if (intNumber >= MILLION) {
    return `${(intNumber / MILLION).toFixed(getDecimals(MILLION, HUNDRED_THOUSAND))}M`;
  } else if (intNumber >= THOUSAND) {
    return `${(intNumber / THOUSAND).toFixed(getDecimals(THOUSAND, HUNDRED))}K`;
  } else {
    return intNumber.toString();
  }
};

export const generateSingleThreadProps = (
  thread: ISingleThread,
  isDraft?: boolean
): {
  threadOverviewContent: IThreadOverviewContentProps;
  threadFooter: IThreadFooterProps;
} => {
  const {
    image,
    commentCount: replyCount,
    content,
    createdAt,
    updatedAt,
    publishedTime,
    like: { status },
    likeCount,
    title,
    user: { username, avatar },
    viewCount,
    id,
  } = thread;

  return {
    threadOverviewContent: {
      image,
      content,
      title,
      id,
      authorInfo: {
        username,
        avatar,
        threadDate: isDraft
          ? updatedAt || createdAt
          : publishedTime || createdAt,
      },
    },
    threadFooter: {
      threadId: id,
      likeCount,
      replyCount,
      viewCount,
      isLiked: status,
    },
  };
};

// TODO: Remove this if unused
export const invalidateMyThreadsQuery = (queryClient: QueryClient) => {
  queryClient.invalidateQueries({
    queryKey: [FORUM_KEYS.threads.myThreads],
  });
};
