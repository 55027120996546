import { useMutation } from '@tanstack/react-query';

import { toastError } from '@shared/utils';

import { productApi } from '../productApi';

const useBookmarkProductMutation = () => {
  return useMutation({
    mutationFn: async (productId: number) =>
      productApi.client.bookmark(productId),
    onError: (e) => {
      toastError(e.message);
    },
  });
};

export default useBookmarkProductMutation;
