import {
  FeeSettingsResponseData,
  SettlementSettingsResponseData,
} from '@interfaces';

import { withClientRequest } from '@api/requestBuilder/client/withClientRequest';
import { withServerRequest } from '@api/requestBuilder/server/withServerRequest';
import { ICallableRequestBuilder } from '@api/requestBuilder/types';

import config from '@shared/config';

const getUserFeeSettings =
  (request: ICallableRequestBuilder<FeeSettingsResponseData>) => async () =>
    request.call(`${config.userApiUrl}/api/settings/fee-settings`);

const getUserSettlementSettings =
  (request: ICallableRequestBuilder<SettlementSettingsResponseData>) =>
  async () =>
    request.call(`${config.userApiUrl}/api/settings/settlement-settings`);

export const settingsApi = {
  client: {
    getUserFeeSettings: withClientRequest(getUserFeeSettings),
    getUserSettlementSettings: withClientRequest(getUserSettlementSettings),
  },
  server: {
    getUserFeeSettings: withServerRequest(getUserFeeSettings),
    getUserSettlementSettings: withServerRequest(getUserSettlementSettings),
  },
};
