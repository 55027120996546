'use client';

import React from 'react';

import { twMerge } from 'tailwind-merge';

import { ChevronDownIcon } from '@shared/assets';

interface IProps {
  direction: 'left' | 'right';
  onClick: () => void;
  className?: string;
  isDisabled?: boolean;
}

const DirectionalArrow = ({
  direction,
  onClick,
  className,
  isDisabled,
}: IProps) => {
  return (
    <button
      className={twMerge(
        'paginationIcon',
        isDisabled ? 'disabled' : 'text-white',
        className
      )}
      onClick={() => {
        if (isDisabled) return;

        onClick();
      }}
    >
      <ChevronDownIcon
        className={twMerge(
          'size-3',
          direction === 'left' ? 'rotate-90' : '-rotate-90'
        )}
      />
    </button>
  );
};

export default DirectionalArrow;
