export enum RatingBadgeType {
  good = 'good',
  bad = 'bad',
  none = 'none',
  medium = 'medium',
}

const RATING_COLORS = {
  good: {
    min: 4.0,
    max: 5.0,
  },
  medium: {
    min: 2.0,
    max: 3.9,
  },
  bad: {
    min: 0,
    max: 1.9,
  },
};

export const getProductRatingsRange = (rating?: number) => {
  if (!rating) {
    return RatingBadgeType.none;
  }

  const { good, medium, bad } = RATING_COLORS;

  if (rating >= good.min && rating <= good.max) {
    return RatingBadgeType.good;
  }

  if (rating >= medium.min && rating <= medium.max) {
    return RatingBadgeType.medium;
  }

  if (rating >= bad.min && rating <= bad.max) {
    return RatingBadgeType.bad;
  }

  return RatingBadgeType.none;
};
