import { twMerge } from 'tailwind-merge';

interface IProps {
  description: string;
  className?: string;
}

const ProductCardDescription = ({ description, className }: IProps) => {
  return (
    <div className={twMerge('relative z-99', className)}>
      <p className="mb-4 line-clamp-3 whitespace-break-spaces break-words font-medium text-neutral300 md:line-clamp-5">
        {description}
      </p>
    </div>
  );
};

export default ProductCardDescription;
