import { twMerge } from 'tailwind-merge';

interface IProps {
  paginationItems: (number | string)[];
  onPageChange: (index: number) => void;
  currentPage: number;
}

const PaginationItems = ({
  paginationItems,
  onPageChange,
  currentPage,
}: IProps) => {
  return (
    <>
      {paginationItems.map((item, index) => (
        <button
          key={index}
          onClick={() => onPageChange(+item)}
          className={twMerge(
            'label-large transitionColor h-10 w-10 border-b border-neutral1000 text-neutral500 hover:border-neutral400 hover:bg-neutral800',
            currentPage === item &&
              'pointer-events-none border-b border-green500 bg-neutral800 text-white hover:border-green500'
          )}
          disabled={item === '...'}
        >
          {item}
        </button>
      ))}
    </>
  );
};

export default PaginationItems;
